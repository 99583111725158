import React from 'react'

type Props = {
	className?: string
}

export const FacebookIcon = ({ className }: Props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 24 24"
			className={className}
			fill="#ffffff"
		>
			<path
				style={{ fill: '#ffffff' }}
				d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm4 7.278V4.5h-2.286c-2.1 0-3.428 1.6-3.428 3.889v1.667H8v2.777h2.286V19.5h2.857v-6.667h2.286L16 10.056h-2.857V8.944c0-1.11.572-1.666 1.714-1.666H16z"
			/>
		</svg>
	)
}
