import * as React from 'react'

export const ArrowButtom = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="11.118" height="11.5" viewBox="0 0 11.118 11.5">
			<defs></defs>
			<g transform="translate(10.059 0.75) rotate(90)">
				<path className="stroke-current" d="M.5,5.5,5,.5l4.5,5" transform="translate(10.5 -0.5) rotate(90)" />
				<line className="stroke-current" y1="10" transform="translate(10 4.5) rotate(90)" />
			</g>
		</svg>
	)
}
