import React from 'react'
import classnames from 'classnames'
import { ScrollToTopButton } from './ScrollToTopButton'
import { container, inner } from './SiteMap.module.scss'
import { ArrowRight, ExternalIcon } from '../ui/Icon'
import { ExternalLink, InternalLink } from '../ui/Link'
import { Logo } from '../ui/Logo'

export const SiteMap = () => {
	return (
		<div className={classnames(container, 'w-full text-secondary bg-gray-darken')}>
			<div className={classnames(inner, 'flex flex-col lg:flex-row lg:justify-between')}>
				<div className="flex flex-col">
					<InternalLink className="inline-flex" href="/">
						<Logo header="white" />
					</InternalLink>
					<p className="mt-6 lg:mt-5 text-xs">〒103-0004</p>
					<p className="mt-2 text-xs">東京都中央区東日本橋2丁目24−9</p>
					<p className="mt-2 text-xs">LIT 4F</p>
				</div>
				<nav className="flex flex-col lg:flex-row flex-wrap lg:flex-nowrap flex-grow lg:justify-end mt-10 lg:mt-0">
					<ul className="w-full lg:w-auto">
						<li className="py-4 lg:py-0 text-sm border-t border-solid lg:border-none">
							<InternalLink
								href="/company"
								className="flex justify-between items-center hover:opacity-70 duration-300"
								activeClass="text-secondary"
							>
								<span>会社情報</span>
								<span className="lg:hidden fill-transparent stroke-current">
									<ArrowRight />
								</span>
							</InternalLink>
						</li>
						<li className="py-4 lg:py-0 lg:mt-4 text-sm border-t border-solid lg:border-none">
							<InternalLink
								href="/mission"
								className="flex justify-between items-center hover:opacity-70 duration-300"
								activeClass="text-secondary"
							>
								<span>ミッション</span>
								<span className="lg:hidden fill-transparent stroke-current">
									<ArrowRight />
								</span>
							</InternalLink>
						</li>
						<li className="py-4 lg:py-0 lg:mt-4 text-sm border-t border-solid lg:border-none">
							<ExternalLink href="https://fooddeliverymanager.jp/" className="flex justify-between items-center">
								<span className="inline-flex items-baseline">
									フードデリバリーマネージャー
									<ExternalIcon />
								</span>
								<span className="lg:hidden fill-transparent stroke-current">
									<ArrowRight />
								</span>
							</ExternalLink>
						</li>
						<li className="py-4 lg:py-0 lg:mt-4 text-sm border-t border-solid lg:border-none">
							<ExternalLink href="https://huriuri.com/" className="flex justify-between items-center">
								<span className="inline-flex items-baseline">
									huriuri（フリウリ）
									<ExternalIcon />
								</span>
								<span className="lg:hidden fill-transparent stroke-current">
									<ArrowRight />
								</span>
							</ExternalLink>
						</li>
					</ul>
					<ul className="lg:ml-10 w-full lg:w-auto border-t border-solid lg:border-none">
						<li className="py-4 lg:py-0 text-sm">
							<ExternalLink
								href="https://hyper-sunshine-6c1.notion.site/Lisa-Technologies-Job-Board-f58a33f35de9473bb70c0fd49cdafd8e"
								className="flex justify-between items-center"
							>
								<span className="inline-flex items-baseline">
									採用情報
									<ExternalIcon />
								</span>
								<span className="lg:hidden fill-transparent stroke-current">
									<ArrowRight />
								</span>
							</ExternalLink>
						</li>
						<li className="py-4 lg:py-0 lg:mt-4 text-sm border-t border-solid lg:border-none">
							<InternalLink
								href="/news"
								className="flex justify-between items-center hover:opacity-70 duration-300"
								activeClass="text-secondary"
							>
								<span>ニュース</span>
								<span className="lg:hidden fill-transparent stroke-current">
									<ArrowRight />
								</span>
							</InternalLink>
						</li>
						<li className="py-4 lg:py-0 lg:mt-4 text-sm border-t border-b border-solid lg:border-none">
							<InternalLink
								href="/contact"
								className="flex justify-between items-center hover:opacity-70 duration-300"
								activeClass="text-secondary"
							>
								<span>お問い合わせ</span>
								<span className="lg:hidden fill-transparent stroke-current">
									<ArrowRight />
								</span>
							</InternalLink>
						</li>
					</ul>
					<ul className="mt-12 lg:mt-0 lg:ml-10 w-full lg:w-auto">
						<li className="text-sm">
							<ExternalLink href="https://note.com/tatupon/" className="inline-flex items-baseline">
								社長note
								<ExternalIcon />
							</ExternalLink>
						</li>
						<li className="mt-4 text-sm">
							<ExternalLink href="https://foodmedia.jp/" className="inline-flex items-baseline">
								Food Media
								<ExternalIcon />
							</ExternalLink>
						</li>
					</ul>
					<ul className="mt-5 lg:mt-0 lg:ml-10 w-full lg:w-auto">
						<li className="text-sm">
							<InternalLink href="/privacy" className="hover:opacity-70 duration-300" activeClass="text-secondary">
								プライバシーポリシー
							</InternalLink>
						</li>
					</ul>
				</nav>
			</div>
			<div className={classnames(inner, 'relative mt-12 text-center')}>
				<span className="text-xs">©︎ LisaTechnologies inc.</span>
				<ScrollToTopButton />
			</div>
		</div>
	)
}
