import React, { FC, ReactNode } from 'react'
import { BreadCrumb } from './BreadCrumb'
import { Footer } from './Footer'
import { Header } from './Header'

type Location = {
	name: string
	path: string
}

type Props = {
	children: ReactNode
	location?: Location[]
}

export const Layout: FC<Props> = ({ children, location }) => {
	return (
		<>
			<Header />
			<main className="w-full">{children}</main>
			<BreadCrumb location={location} />
			<Footer />
		</>
	)
}
