import * as React from 'react'

export const ArrowLeft = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 11.5 11.118">
			<defs></defs>
			<g transform="translate(0.75 1.059)">
				<g transform="translate(14 9) rotate(180)">
					<path className="text-secondary" d="M.5,5.5,5,.5l4.5,5" transform="translate(14.5 -0.5) rotate(90)" />
					<line className="text-secondary" y1="14" transform="translate(14 4.5) rotate(90)" />
				</g>
			</g>
		</svg>
	)
}
