import React, { VFC } from 'react'
import classnames from 'classnames'
import { dark, white } from './Logo.module.scss'

type Props = {
	className?: string
	header: 'white' | 'dark'
}

export const Logo: VFC<Props> = ({ className, header = 'dark' }) => {
	if (header === 'dark') return <div className={classnames('bg-center bg-no-repeat bg-contain', dark, className)} />

	return <div className={classnames('bg-center bg-no-repeat bg-contain', white, className)} />
}
