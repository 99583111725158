import React, { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames'
import {
	activeMenu,
	contactButton,
	externalLink,
	headerOn,
	internalLink,
	menuTrigger,
	normalMenu,
} from './Header.module.scss'
import { ArrowRight, ExternalIcon } from '../ui/Icon'
import { ExternalLink, InternalLink } from '../ui/Link'
import { Logo } from '../ui/Logo'

const headerLink = [
	{ name: '会社情報', href: '/company' },
	{ name: 'ミッション', href: '/mission' },
	{ name: 'サービス', href: 'https://fooddeliverymanager.jp/' },
	{
		name: '採用情報',
		href: 'https://hyper-sunshine-6c1.notion.site/Lisa-Technologies-Job-Board-f58a33f35de9473bb70c0fd49cdafd8e',
	},
	{ name: 'ニュース', href: '/news' },
	{ name: 'お問い合わせ', href: '/contact' },
]

export const Header = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [ctaPosition, setCtaPosition] = useState<number>(0)
	const [isHeaderSwitch, setIsHeaderSwitch] = useState<boolean>(false)

	// レスポンシブ
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden'
		}

		return () => {
			document.body.style.overflow = 'scroll'
		}
	}, [isOpen])

	useEffect(() => {
		const { pathname } = window.location

		let ctaHeight = 0

		if (pathname === '/') {
			ctaHeight = document.getElementById('cta').clientHeight
			const position = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)

			if (ctaHeight - 100 <= position) {
				setIsHeaderSwitch(true)
			}
		} else {
			setIsHeaderSwitch(true)
		}

		setCtaPosition(ctaHeight)
	}, [])

	useEffect(() => {
		const onScroll = () => {
			const position = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)

			if (ctaPosition - 100 <= position) {
				setIsHeaderSwitch(true)
			} else {
				setIsHeaderSwitch(false)
			}
		}

		const { pathname } = window.location

		if (pathname === '/') {
			document.addEventListener('scroll', onScroll)
		}

		return () => {
			document.removeEventListener('scroll', onScroll)
		}
	}, [ctaPosition])

	const handleMenuOpen = useCallback(() => {
		setIsOpen((prev) => !prev)
	}, [])

	return (
		<header className={classnames(isHeaderSwitch ? headerOn : '', 'overflow-hidden fixed top-0 left-0 z-100 w-full')}>
			<nav className="lg:py-6 lg:px-12 bg-transparent">
				{/* for desktop menu */}
				<div className="hidden lg:flex lg:justify-between lg:items-center w-auto">
					<InternalLink className="inline-flex" href="/">
						<Logo header={isHeaderSwitch ? 'dark' : 'white'} />
					</InternalLink>
					<div className={classnames('flex items-center', isHeaderSwitch ? 'text-primary' : 'text-secondary')}>
						{headerLink.map((link) => {
							if (link.href.includes('https')) {
								return (
									<ExternalLink key={link.name} href={link.href} className="inline-flex items-baseline mr-10 text-sm">
										{link.name}
										<ExternalIcon />
									</ExternalLink>
								)
							} else {
								return (
									<InternalLink key={link.name} href={link.href} className="inline-block mr-10 text-sm">
										{link.name}
									</InternalLink>
								)
							}
						})}
					</div>
				</div>
				{/* for mobile menu */}
				<div
					className={classnames(
						'flex lg:hidden flex-col w-full',
						isOpen ? 'min-h-screen bg-secondary' : isHeaderSwitch ? 'bg-secondary' : ''
					)}
				>
					<div className="flex justify-between items-center p-4">
						{isOpen || isHeaderSwitch ? (
							<InternalLink className="inline-flex" href="/">
								<Logo header="dark" />
							</InternalLink>
						) : (
							<InternalLink className="inline-flex" href="/">
								<Logo header="white" />
							</InternalLink>
						)}
						<div className={classnames(menuTrigger, 'flex items-center p-2')} onClick={handleMenuOpen}>
							<span className={isOpen ? activeMenu : classnames(isHeaderSwitch ? 'bg-primary' : normalMenu)}></span>
							<span className={isOpen ? activeMenu : classnames(isHeaderSwitch ? 'bg-primary' : normalMenu)}></span>
							<span className={isOpen ? activeMenu : classnames(isHeaderSwitch ? 'bg-primary' : normalMenu)}></span>
						</div>
					</div>
					{isOpen && (
						<>
							<div className="flex left-0 flex-col items-start px-8 mt-7 w-full">
								{headerLink.map((link) => {
									if (link.href.includes('https')) {
										return (
											<ExternalLink
												key={link.name}
												href={link.href}
												className={classnames(
													externalLink,
													'inline-flex justify-between items-center py-4 w-full text-sm border-t border-solid'
												)}
											>
												<span className="inline-flex items-baseline stroke-0">
													{link.name}
													<ExternalIcon />
												</span>
												<ArrowRight />
											</ExternalLink>
										)
									} else {
										return (
											<InternalLink
												key={link.name}
												href={link.href}
												className={classnames(
													internalLink,
													link.href === '/contact' ? contactButton : '',
													'flex justify-between items-center py-4 w-full text-sm border-t border-solid'
												)}
											>
												<span className="z-10">{link.name}</span>
												<ArrowRight />
											</InternalLink>
										)
									}
								})}
							</div>
							<p className="py-9 mt-auto text-xs text-center">©︎ LisaTechnologies inc.</p>
						</>
					)}
				</div>
			</nav>
		</header>
	)
}
