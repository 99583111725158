import React, { FC } from 'react'
import classnames from 'classnames'
import { Link } from 'gatsby'
import { activeLink } from './InternalLink.module.scss'

type Props = {
	className?: string
	activeClass?: string
	href: string
	onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export const InternalLink: FC<Props> = ({ children, activeClass, className, href, onClick }) => {
	return (
		<Link
			to={href}
			className={classnames(className, 'hover:opacity-70 duration-300')}
			activeClassName={classnames(activeClass, activeLink)}
			onClick={onClick}
		>
			{children}
		</Link>
	)
}
