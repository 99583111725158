import React from 'react'
import classnames from 'classnames'

type Props = {
	className?: string
}

export const ExternalIcon = ({ className }: Props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={classnames(className, 'ml-2')}
			width="11"
			height="11"
			viewBox="0 0 11 11"
		>
			<path
				className="fill-current"
				d="M7.5,6.9a.472.472,0,0,0-.5.5V10H1V4H3.6a.472.472,0,0,0,.5-.5A.472.472,0,0,0,3.6,3H.5a.472.472,0,0,0-.5.5v7a.472.472,0,0,0,.5.5h7a.472.472,0,0,0,.5-.5V7.4A.472.472,0,0,0,7.5,6.9Z"
			/>
			<path
				className="fill-current"
				d="M10.9.1a.6.6,0,0,0-.4-.1H6.2a.536.536,0,0,0-.5.5.536.536,0,0,0,.5.5H9.3L5.1,5.1a1.1,1.1,0,0,0,0,.8.6.6,0,0,0,.4.1.6.6,0,0,0,.4-.1L10,1.7v3a.472.472,0,0,0,.5.5.458.458,0,0,0,.5-.4V.5A.756.756,0,0,0,10.9.1Z"
			/>
		</svg>
	)
}
