import React from 'react'
import { arrow, circle, line, path, topScroll } from './ScrollToTopButton.module.scss'

export const ScrollToTopButton = () => {
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}

	return (
		<button
			name="to-top"
			className="absolute right-0 -bottom-0.5 border-none hover:opacity-70 duration-300"
			onClick={scrollToTop}
		>
			<svg className={topScroll} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
				<defs></defs>
				<circle className={circle} cx="20" cy="20" r="20" />
				<g transform="translate(16 10)">
					<path className={arrow} d="M.5,5.5,5,.5l4.5,5" transform="translate(-0.5 9.5)" />
					<line className={path} y1="10" transform="translate(4.5 11)" />
					<line className={line} x2="9" />
				</g>
			</svg>
		</button>
	)
}
