import React from 'react'
import { SiteMap } from './SiteMap'

export const Footer = () => {
	return (
		<footer className="flex flex-col w-full">
			<SiteMap />
		</footer>
	)
}
