import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import { container } from './BreadCrumb.module.scss'

type Location = {
	name: string
	path: string
}

type Props = {
	location?: Location[]
}

export const BreadCrumb = ({ location }: Props) => {
	return (
		<div className={classnames(container, 'py-6 w-full')}>
			<Link
				to="/"
				activeStyle={{ color: 'var(--color-blue)' }}
				activeClassName="tex-blue"
				className="text-xs lg:text-sm hover:opacity-70 duration-300"
			>
				ホーム
			</Link>
			{location.map((location) => {
				return (
					<span key={location.name}>
						<span className="mx-2 text-xs lg:text-sm">/</span>
						<Link
							to={location.path}
							activeStyle={{ color: 'var(--color-blue)' }}
							activeClassName="tex-blue"
							className="text-xs lg:text-sm hover:opacity-70 duration-300"
						>
							{location.name}
						</Link>
					</span>
				)
			})}
		</div>
	)
}
