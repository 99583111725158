import React, { ComponentPropsWithRef, forwardRef } from 'react'
import classnames from 'classnames'
import { withIconContainer, fluffyContainer, fluffyTxt } from './ExternalLink.module.scss'
import { ExternalIcon } from '../Icon'

type BaseProps = ComponentPropsWithRef<'a'>

interface Props extends BaseProps {
	className?: string
	between?: boolean
}

export const ExternalLink = forwardRef<HTMLAnchorElement, Props>(
	({ children, className, between = false, ...props }, ref) => {
		const rootClassName = classnames({ [withIconContainer]: between }, className, 'hover:opacity-70 duration-300')

		return (
			<a {...props} ref={ref} target="_blank" rel="noopener noreferrer" className={rootClassName}>
				{children}
			</a>
		)
	}
)

export const ExternalLinkFluffy = forwardRef<HTMLAnchorElement, Props>(({ children, className, ...props }, ref) => {
	return (
		<a
			{...props}
			ref={ref}
			target="_blank"
			rel="noopener noreferrer"
			className={classnames(className, fluffyContainer, 'hover:duration-300')}
		>
			<span className={fluffyTxt}>{children}</span>
			<ExternalIcon />
		</a>
	)
})
